import {get} from 'lodash';
import 'whatwg-fetch';

let fetchAndCheck = (url, options) => {

    return fetch(url, options)
        .then((res) => {
            if (res.ok) {
                return res
                    .json()
                    .then(body => {
                        let status = get(body, 'error.status');
                        if (status && status > 200) {

                            return Promise.reject(body);
                        }

                        return body;
                    });
            } else {
                let error = new Error(res.statusText);
                error.status = res.status;

                return res
                    .json()
                    .catch(() => {
                        return Promise.reject(error);
                    })
                    .then(err => {
                        return Promise.reject(err);
                    });
            }
        });
};

export default fetchAndCheck;
'use strict';
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import {get, merge} from 'lodash';

import {connect} from 'react-redux';
import Parse from 'parse';

import {
    calculateMeanTempAndHumidity,
    floorNameFromNumber,
    getCategoryOfSaving,
    manageError,
    redirectIfNotLogged,
    splitRoomByFloor,
    toPointerFromId,
    userIsInRole
} from '../../lib/util';
import translate from '../translate';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import FloorRoom from './floor-room';
import db from '../../lib/structure';
import moment from 'moment';
import Loader from '../loader';
import SeeAsUser from '../card-seeAsUser';
import CardWeather from '../card-weather';
import structureLocalStorage, {BUILDING_SELECTED_FLOOR} from '../../lib/localStorageStructure';
import AddRoomModal from '../add-room-modal';
import CardSummerMode from '../card-summer-mode';

class BuildingPage extends React.Component {
    constructor(props){
        super(props);

        let currentFloorNumber = this.props.match.params.floorNumber;

        this.state = {
            rooms: [],
            selectedFloor: currentFloorNumber,
            floors: []
        };
        this.loadRooms = this.loadRooms.bind(this);
        this.onFloorSelected = this.onFloorSelected.bind(this);
        this.reload = this.reload.bind(this);
    }

    async componentDidMount(){

        try {
            await redirectIfNotLogged(this.props.history);

            await this.reload();
        } catch (err) {
            manageError(err, this.props);
        }

        $('.select-custom').on('click', function (){
            $(this).toggleClass('active');
        });
    }

    onFloorSelected(floorNumber){
        localStorage.setItem(BUILDING_SELECTED_FLOOR, floorNumber);
        this.setState({selectedFloor: floorNumber})
    }

    async loadHome(){
        let homeId = localStorage.getItem('buildingSelection');
        try {
            let home = await new Parse.Query(db.classes.Home).get(homeId);
            this.setState({home});
        } catch (e) {
            console.error(e);
        }
    }

    async reload(){
        await this.loadRooms();
        await this.loadHome();

        let urlSelectedFloor = this.props.match.params.floorNumber + '';
        let floors = splitRoomByFloor(this.state.rooms);
        let floorsNumber = Object.keys(floors);

        let currentFloorNumber = floorsNumber.indexOf(urlSelectedFloor) >= 0 ?
            urlSelectedFloor :
            (floors && Object.keys(floors)[0]);

        this.setState({floors: floors, selectedFloor: currentFloorNumber});
    }

    render(){
        const {t} = this.props;
        let buildingSelectedFloor = localStorage.getItem(BUILDING_SELECTED_FLOOR);
        let urlSelectedFloor = (buildingSelectedFloor || this.props.match.params.floorNumber) + '';
        let floorsNumber = Object.keys(this.state.floors);

        let currentFloorNumber = floorsNumber.indexOf(urlSelectedFloor) >= 0 ?
            urlSelectedFloor :
            (this.state.floors && Object.keys(this.state.floors)[0]);

        let allowedSeeAsUser = userIsInRole('Support');
        const typeOfCustomer = localStorage.getItem(`home:${db.Home.TYPE_OF_CUSTOMER}`);
        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';
        const currentUser = Parse.User.current();

        return <div className={'page-building'}>
            {
                get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.generalError.message')}
                </div>
            }

            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <AddRoomModal {...this.props} onAfterSubmitSuccess={() => this.reload()}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: '/dashboard',
                                name: t('Home')
                            },
                            {
                                link: null,
                                name: t('Building')
                            }
                        ]}/>
                        <h1>
                            {t('Building')}
                            {
                                hasWritePermission && <svg
                                    className="icon icon-add icon-add-room"
                                    data-toggle="modal"
                                    data-target="#modal-dashboard-actions"
                                >
                                    <use href={'/assets/images/sprite.svg#add'}></use>
                                </svg>
                            }
                        </h1>
                        <ul className="nav nav-tabs header-tabs hide-sm" role="tablist" id={'select-floor-no-mobile'}>
                            {
                                this.state.floors && Object.keys(this.state.floors).sort((a, b) => a - b).map(floorNumber => {

                                    return <li key={floorNumber} className="nav-item" onClick={() => this.onFloorSelected(floorNumber)}>
                                        <a className={`nav-link ${currentFloorNumber === floorNumber ? 'active' : ''} show`}
                                            id={`select-item-floor-${floorNumber}`}
                                            data-toggle="tab"
                                            href={`#${floorNumber}`}
                                            role="tab"
                                            aria-controls="first-floor"
                                            aria-selected="true"
                                        >
                                            {t(floorNameFromNumber(floorNumber))} {}
                                        </a>
                                    </li>;
                                })
                            }
                        </ul>

                        <div className="select-custom floor-select">
                            <div
                                className="select-custom-status">{t(floorNameFromNumber(this.state.selectedFloor))}</div>
                            <ul className="nav nav-tabs header-tabs select-custom-list" id={'select-floor-mobile'} role="tablist">
                                {
                                    this.state.floors && Object.keys(this.state.floors).sort((a, b) => a - b).map(floorNumber => {
                                        return <li key={floorNumber} className="nav-item select-custom-item">
                                            <a className={`nav-link ${currentFloorNumber === floorNumber ? 'active' : ''} show`}
                                                id={`select-item-floor-${floorNumber}`}
                                                data-toggle="tab"
                                                href={`#${floorNumber}`}
                                                role="tab"
                                                aria-controls="first-floor"
                                                aria-selected="true"
                                                onClick={() => this.onFloorSelected(floorNumber)}
                                            >
                                                {t(floorNameFromNumber(floorNumber))} {}
                                            </a>
                                        </li>;
                                    })
                                }
                                {
                                    !this.state.floors && <Loader/>
                                }
                            </ul>
                        </div>

                        <div className="tab-content">
                            {
                                this.state.floors && Object.keys(this.state.floors).sort((a, b) => a - b).map((floorNumber, i) => {
                                    let rooms = this.state.floors[floorNumber];
                                    let mean = calculateMeanTempAndHumidity(rooms);

                                    return <div
                                        key={i}
                                        className={`tab-pane fade ${currentFloorNumber === floorNumber ? 'active show' : ''}`}
                                        id={floorNumber}
                                        role="tabpanel"
                                        aria-labelledby="first-floor">
                                        <div className="grid-cols building-view">
                                            <div className="left-col grid-building-view">
                                                {rooms && rooms.map(room => {
                                                    let isDemoAccount = localStorage.getItem('demoAccount') === 'true';
                                                    let mainPhoto = room.get(db.Room.MAIN_PHOTO);
                                                    let mainPhotoUrl = room.get(db.Room.MAIN_PHOTO_URL);
                                                    let photoUrl = null;

                                                    if(mainPhoto)
                                                        photoUrl = mainPhoto._url;
                                                    else {
                                                        if(mainPhotoUrl){
                                                            photoUrl = mainPhotoUrl;
                                                        }
                                                    }
                                                    let showEstimatedRoomTemperature = room?.get(db.Room.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART) ??
                                                        this.state.home?.get(db.Home.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART) ?? false;

                                                    let avgEstimatedTempLastHour = room.get(db.Room.AVG_ROOM_EST_TEMP_LAST_HOUR);
                                                    let avgTempLastHour = room.get(db.Room.AVG_TEMP_LAST_HOUR);
                                                    let avgHumLastHour = room.get(db.Room.AVG_HUM_LAST_HOUR);
                                                    let energySavingPercentage = room.get(db.Room.ENERGY_SAVING_PERCENTAGE);
                                                    let numberOfRadiators = room.get(db.Room.NUMBER_RADIATORS);
                                                    let numberSensp = room.get(db.Room.NUMBER_SENSP);
                                                    let co2 = room.get(db.Room.CO2);

                                                    let lastUpdateAvgTemp = room.get(db.Room.LAST_UPDATE_AVG_TEMP);
                                                    let differenceFromNowInMinutes = 1000000;
                                                    if(lastUpdateAvgTemp){
                                                        lastUpdateAvgTemp = moment(lastUpdateAvgTemp);
                                                        differenceFromNowInMinutes = Math.abs(lastUpdateAvgTemp.tz('Europe/Zurich').diff(moment(), 'minutes'));
                                                    }

                                                    if(differenceFromNowInMinutes >= 43200){
                                                        avgEstimatedTempLastHour = null;
                                                        avgHumLastHour = null;
                                                        avgTempLastHour = null;
                                                        co2=null;
                                                    }

                                                    let displayedTemperature;

                                                    if(
                                                        showEstimatedRoomTemperature === true &&
                                                        avgEstimatedTempLastHour != null
                                                    )
                                                        displayedTemperature = avgEstimatedTempLastHour;
                                                    else
                                                        displayedTemperature = avgTempLastHour;

                                                    let temperature = displayedTemperature && parseFloat(displayedTemperature).toFixed(1);

                                                    return <FloorRoom
                                                        {...this.props}
                                                        key={room.id}
                                                        id={room.id}
                                                        temperature={temperature}
                                                        humidity={avgHumLastHour && parseFloat(avgHumLastHour).toFixed(1)}
                                                        savedEnergy={getCategoryOfSaving(energySavingPercentage)}
                                                        co2={co2}
                                                        numberRadiators={numberOfRadiators}
                                                        numberSensp={numberSensp}
                                                        roomName={room.get(db.Room.ROOM_NAME)}
                                                        lastUpdate={lastUpdateAvgTemp?.format('DD MMM YYYY, HH:mm')}
                                                        mainPhotoUrl={photoUrl}
                                                        room={room}
                                                        t={t}
                                                    />
                                                })}
                                            </div>
                                            <div className="right-col grid-building-view">
                                                {
                                                    this.state.rooms.length > 0 && <CardWeather {...this.props}/>
                                                }

                                                {
                                                    typeOfCustomer !== db._User.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER &&
                                                    hasWritePermission &&
                                                    <CardSummerMode {...this.props}/>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    {allowedSeeAsUser && <SeeAsUser {...this.props}/>}
                    <Footer/>
                </div>
            </div>
        </div>
    }

    async loadRooms(){
        let currentHome = localStorage.getItem('buildingSelection');

        let query = new Parse.Query('Room');
        query.equalTo('home', toPointerFromId(currentHome, 'Home'));
        query.notEqualTo(db.Room.DELETED, true);
        query.notEqualTo(db.Room.HIDDEN, true);
        query.limit(10000);

        try {
            let rooms = await query.find();

            this.setState({rooms: rooms});
        } catch (err) {
            manageError(err, this.props);
        }
    }
}

BuildingPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired,
    match: PropTypes.object
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(BuildingPage));
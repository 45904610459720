'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import translate from '../translate';
import Footer from '../footer';
//import floorPlanExample from '../../assets/images/floor-plan-example.svg';

class PageFloorPlan extends React.Component {
    constructor(props){
        super(props);

        this.state = {};
    }

    async componentDidMount(){
        document.getElementById('12345').addEventListener('click', function(e) {
            e.currentTarget.setAttribute('fill', '#ff00cc');
            var mydiv = document.createElement('div');
            mydiv.style.height = '100px';
            mydiv.style.width = '100px';
            mydiv.style.backgroundColor = "#dc143c";
            mydiv.style.zIndex = 99999;
            mydiv.innerHTML = 'Welcome!';
            mydiv.style.position = 'absolute';
            mydiv.style.top = e.pageY + 'px';
            mydiv.style.left = e.pageX + 'px';
            document.getElementsByTagName('body')[0].appendChild(mydiv);
        });

        document.getElementById('svg1').addEventListener('click', function (e){
            console.log('svg');
        })
    }

    render(){
        const {t} = this.props;

        //Open DXF https://sharecad.org/
        //Convert DXF to SVG with all levels https://www.autodwg.com/dxf-to-svg/

        return <div className={'system-diagnostics'}>
            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: null,
                                name: t('All support requests')
                            }
                        ]}/>
                        <div className='card card-outer full-w' style={{padding: '20px'}}>
                            {/*<img src={floorPlanExample} />*/}
                        </div>
                    </div>
                    <Footer {...this.props}/>
                </div>
            </div>
        </div>
    }
}

PageFloorPlan.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.any.isRequired,
    location: PropTypes.object
};

export default translate(PageFloorPlan);
